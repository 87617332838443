#BookList {
  display: grid;
  gap: 2rem;
  width: 90vw;
  margin: 2rem auto;
  max-width: 1170px;
  align-items: start;
}
@media (min-width: 1001px) {
  #BookList {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (min-width: 768px) and (max-width: 1000px) {
  #BookList {
    grid-template-columns: 1fr 1fr;
  }
}
.book {
  background: #fff;
  border-radius: 1rem;
  padding: 2rem;
  text-align: center;
  position: relative;
}
.book > img {
  width: 60%;
  object-fit: cover;
}
.book > h4 {
  letter-spacing: 2;
}
.book > p {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 1rem;
  padding: 0.75rem;
  border-top-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  background-color: #c35600;
  color: white;
}
