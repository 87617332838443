* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  background-color: #f1f5f8;
  color: #1b1b1b;
}
h1 {
  text-align: center;
  border: 2px dashed black;
  padding: 20px;
  margin: 1rem auto;
  width: 30%;
  border-radius: 10px;
}
